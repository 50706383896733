import React from "react"
import './footer.css'


function Footer() {
    return (    
        <footer>
          <div className="footer-flex">
            <div className="left">
              <ul>
                <li><a href="https://www.instagram.com/newpragmatic_/" target="_blank">Instagram</a></li>
                <li><a href="https://www.youtube.com/channel/UCt3YbqkHkGpMjk8I6Sd0mvQ?sub_confirmation=1" target="_blank">YouTube</a></li>
                <li><a href="https://www.linkedin.com/company/newpragmatic" target="_blank">LinkedIn</a></li>
                <li><a href="https://twitter.com/newpragmatic_" target="_blank">Twitter</a></li>
              </ul>
              <h4>Copyright © 2021 New Pragmatic</h4>
            </div>
          </div>
        </footer>
    )
}

export default Footer